.custom-context{
    border: solid 1px #ccc;
    display: inherit;
    margin: 5px;
    background: #FFF;
    color: #000;
    font-family: sans-serif;
    cursor: pointer;
    font-size: 12px;
    position: absolute;
}

.custom-context-item{
    display: inherit;
    border-bottom: dotted 1px #ccc;
    padding: 5px 25px;
}
.custom-context-item-last{
    padding: 5px 25px;
}

#cmenu {
    position: absolute;
    z-index: 1000;
}

.var-custom-context{
    border: solid 1px #ccc;
    display: inherit;
    margin: 5px;
    background: #FFF;
    color: #000;
    font-family: sans-serif;
    cursor: pointer;
    font-size: 12px;
    height: 300px;
    overflow: scroll;
    width: 484px;
}

.var-custom-context-item{
    display: inherit;
    border-bottom: dotted 1px #ccc;
    padding: 5px 25px;
    text-align: left;
}

.var-custom-context-item-last{
    padding: 5px 25px;
}

#vmenu {
    position: absolute;
    z-index: 1000;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.var-custom-context-item {
    display: inherit;
    padding-left: 20px;
}

.var-error-msg {
    width: 265px;
    border: 1px solid #000;
    margin: 8px;
    background: #fff;
    color: #000;
    font-family: sans-serif;
    cursor: pointer;
    font-size: 19px;
}

.var-context-error-msg {
    padding-left: 12px;
    font-size: 16px;
    display: flex;
    padding-right: 25px;
}

/* .variable-menu-hdr {
    font-size: 19px;
    padding-left: 17px;
    font-weight: bold;
} */

.context-menu-hdr {
    font-size: 19px;
    padding-left: 8px;
    font-weight: bold;
}

.close-var-menu-img {
    position: absolute;
    left: 90%;
    top: 8%;
}

.close-var-err-msg {
    position: absolute;
    left: 84%;
    top: 17%;
}