/* Title */
.title {
    position: relative;
    background-color: #696969;
    margin-bottom: 5px;
    border-radius: 3px;
    padding-bottom: 37px;
}

.template-editor-title {
    display: inline;
    font-size: 22px;
    color: #fff;
    position: relative;
    top: 20px;
    left: 2%;
}

.title-input {
    width: 83.25%;
    height: 34px;
    font-size: 15px;
    position: relative;
    left: 5.75%;
    top: 17px;
}

#title-drag-component {
    position: relative;
    left: .5%;
    top: 59px;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-title-btn {
    color: white;
    position: absolute;
    top: 15px;
    left: 95%;

}

/* HTML */
.html {
    position: relative; 
    width: 99.75%;
    margin-bottom: 10px;
}

.html-input {
    width: 99.75%;
    margin-bottom: 10px;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-html-btn {
    color: #606060;
    position: absolute;
    left: 95.26%;
    top: -5px;
    z-index: 1;
}

/* Section */
.template-editor-section {
    display: inline;
    font-size: 18px;
    color: #fff;
    position: relative;
    top: 23px;
    left: 2%;
}

.section-input {
    width: 82.75%;
    height: 25px;
    font-size: 15px;
    position: relative;
    left: 4.75%;
    top: 20px;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-section-btn {
    position: absolute;
    left: 95%;
    top: 10px;
    color: white;
}

/* Variable */
.variable {
    position: relative;
    background-color: lightgray;
    border-radius: 3px;
    margin-bottom: 5px;
    padding-top: 15px;
    padding-bottom: 59px;
}

.template-editor-variable {
    display: inline;
    font-size: 17px;
    position: relative;
    left: 2%;
    padding-right: 17px;
    top: 6px;
}

.variable-inputs {
    position: relative;
    left: 2%;
    top: 23px;
    display: inline;
}

.variable-description-input {
    width: 26%;
}

.variable-inputs input {
    margin-right: 85px;
    width: 30%;
}

.variable-inputs label {
    font-size: 15px;
    padding-right: 10px;
}

.variable-type-label {
    position: relative;
    top: 3px;
}

.variable-editor-dropdown {
    position: relative;
    top: 4px;
    left: 51px;
    width: 30.75%;
    height: 23px;
    margin-bottom: 14px;
    margin-right: 50px;
}

.variable-name-input {
    position: relative;
    left: 44px;
}

.variable-description-label {
    position: relative;
    top: 7px;
}

.variable-description-input {
    position: relative;
    top: 7px;
    left: 8px;
}

.variable-options-label {
    position: relative;
    top: 13px;
}

.variable-options-input {
    position: relative;
    top: 13px;
    left: 47px;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-dropdown-btn,
button.MuiButtonBase-root.MuiIconButton-root.remove-text-btn,
button.MuiButtonBase-root.MuiIconButton-root.remove-checkbox-btn,
button.MuiButtonBase-root.MuiIconButton-root.remove-list-btn,
button.MuiButtonBase-root.MuiIconButton-root.remove--btn,
button.MuiButtonBase-root.MuiIconButton-root.remove-image-btn,
button.MuiButtonBase-root.MuiIconButton-root.remove-datasource-btn,
button.MuiButtonBase-root.MuiIconButton-root.remove-textarea-btn,
button.MuiButtonBase-root.MuiIconButton-root.remove-richtextarea-btn {
    position: absolute;
    left: 95%;
    top: 4%;
}

/* Header/Footer */
.header, .footer {
    position: relative;
    background-color: #464646;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 5px;
    border-radius: 3px;
}

.template-editor-header, .template-editor-footer  {
    color: white;
    display: inline;
    position: relative;
    left: 2%;
    font-size: 20px;
}

.header-input {
    width: 83%;
    height: 28px;
    position: relative;
    left: 4%;
}

.footer-input {
    width: 83%;
    height: 28px;
    position: relative;
    left: 4.75%;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-header-btn, 
button.MuiButtonBase-root.MuiIconButton-root.remove-footer-btn {
    position: absolute;
    left: 95%;
    bottom: 13px;
    color: white;
}


/* Switch */
.switch {
    position: relative;
    background-color: #b2bac5;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 29px;
    border-radius: 3px;
}

.template-editor-switch {
    display: inline;
    font-size: 17px;
    position: relative;
    left: 2%;
    top: 11px;padding-right: 17px;
}

.switch-inputs {
    display: inline;
    position: relative;
    left: 3%;
    top: 10px;
}

.switch-inputs input {
    margin-right: 85px;
    width: 21%;
}

.switch-inputs label {
    font-size: 15px;
    padding-right: 10px;
}

#meta-value {
    background-color: #c8c8cf;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 3px;
    margin-bottom: 5px;
}

.template-editor-value {
    display: inline;
    position: relative;
    left: 2%;
}

.expression-value-input {
    position: relative;
    left: 4%;
    width: 30%;
    height: 16px;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-switch-btn {
    position: absolute;
    left: 95%;
}

._meta_value {
    position: relative;
    padding-top: 13px;
    padding-bottom: 10px;
}

._meta_default {
    position: relative;
    padding-top: 13px;
    padding-bottom: 10px;
}

.\@meta\:value, .\@meta\:default {
    background-color: #e2e2e2;
    border-radius: 3px;
    margin-bottom: 5px;
} 

button.MuiButtonBase-root.MuiIconButton-root.remove-value-btn {
    position: relative;
    left: 5%;
    top: 0px;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-default-btn {
    position: relative;
    left: 3%;
    top: 0px;
}

/* Template */
.template {
    position: relative;
    background-color: #a6afa3;
    border-radius: 3px;
    padding-top: 39px;
    margin-bottom: 5px;
    min-height: 92px;
    height: auto;
}

.template-editor-template {
    display: inline;
    position: relative;
    left: 2%;
}

.template-dropdown {
    position: absolute;
    width: 30.75%;
    height: 30px;
    left: 15%;
    top: 29px;
}

.template-name-label {
    position: absolute;
    left: 2%;
    top: 78px;
}

.template-name-input {
    position: absolute;
    width: 30%;
    height: 25px;
    left: 15%;
    top: 70px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.expand-template-button {
    position: absolute;
    right: 2%;
    top: 79px;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-template-btn {
    position: absolute;
    left: 95%;
    top: 16px;
}

/* Table of Contents */
.table_of_contents {
    position: relative;
    background-color: lightgray;
    border-radius: 3px;
    width: 100%;
    height: 180px;
    margin-bottom: 5px;

}
.template-editor-table_of_contents {
    display: inline;
    position: relative;
    left: 44%;
    top: 17px;
    font-size: 20px;
    font-weight: 700;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-table-of-contents-btn {
    position: absolute;
    bottom: 125px;
    left: 95%;

}

/* Table */
.template-editor-table {
    display: inline;
    position: relative;
    left: 2%;
    top: 16px;
    font-size: 19px;
}

.table-inputs {
    position: relative;
    left: 2%;
    top: 30px;
}

.datasource-input {
    position: relative;
    left: 3%;
}

.table-title-input {
    position: relative;
    left: 7.25%;
}

.row-headers-input {
    position: relative;
    left: 2%;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-table-btn {
    position: absolute;
    left: 95%;
    top: 7px;
}

/* Image */
.image {
    position: relative;
    background-color: #cacaca;
    border-radius: 3px;
    margin-bottom: 5px;
    height: 50px;
}

.template-editor-image {
    display: inline;
    position: relative;
    left: 2%;
    top: 16px;
    font-size: 16px;
}

/* Reference */
.reference {
    position: relative;
    background-color: #cacaca;
    border-radius: 3px;
    margin-bottom: 5px;
    height: 145px;
}

.template-editor-reference {
    display: inline;
    position: relative;
    left: 2%;
    top: 16px;
    font-size: 16px;
}

.reference-name-header {
    display: inline;
    position: relative;
    top: 52px;
    font-size: 14px;
    right: 55px;
}

.reference-name-input {
    position: relative;
    top: 50px;
    width: 234px;
    right: 43px;
    height: 20px;
}

.select-ref-element {
    position: relative;
    top: 57px;
    left: 19px;
    font-size: 14px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.reference-dropdown {
    position: relative;
    top: 17px;
    left: 151px;
    width: 76%;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-reference-btn {
    position: absolute;
    left: 95%;
    top: 4px;
}

/* Subtemplate */
.expanded-template {
    background-color: white;
    position: relative;
    top: 53px;
    padding-top: 27px;
    padding-bottom: 36px;
}

/* Nunjucks */
.style {
    position: relative;
    background-color: gray;
    border-radius: 3px;
    padding-bottom: 30px;
    margin-bottom: 5px;
}

.style-name-label {
    position: relative;
    right: 55px;
    top: 20px;
}

.style-name-input {
    position: relative;
    top: 18px;
    left: 12px;
}

.style-element-type {
    display: inherit;
    position: relative;
    left: 20px;
    top: 35px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.element-type-dropdown {
    position: relative;
    left: 140px;
    top: 8px;
    width: 150px;
}

.style-input {
    width: 95%;
    height: 68%;
    position: relative;
    left: 17px;
    top: 34px;
}

button.MuiButtonBase-root.MuiIconButton-root.remove-style-btn {
    position: absolute;
    left: 94%;
    top: 2px;
    color: white;
}

.template-editor-style {
    display: inline;
    font-size: 16px;
    position: relative;
    left: 1.2em;
    top: 16px;
}

.style-default-inputs, .style-section-inputs {
    position: relative;
    left: 20px;
    top: 22px;
    padding-bottom: 4px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.numbering-scheme-dropdown {
    position: relative;
    width: 63%;
    left: 20px;
}