.template-tree{
    font-size: 20px;
}

.template-router-button{
    width: fit-content;
    box-shadow: 0 0 2px 0px rgb(107, 107, 107);
    border-radius: 2px;
    margin:10px;
}

.grid-title{
    padding: 5px;
}

