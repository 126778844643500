/* .react-flow__pane {
	background-color: gray;
} */

.react-flow-container {
	border-radius: 3px;
	border-width: 4px;
	border-style: solid;
	border-color: #2a2c33;
	margin-bottom: 3em;
	width: 85%;
	margin-left: 7%;
}

.read-flow__node-default {
	width: 7%;
}
