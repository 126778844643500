.MuiFormControl-root {
    width: 90%;
}

.input-element {
    margin-bottom: 16px;
}

.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon {
    width: 90%;
}

.template-element-dialog-container {
    height: 45vh;
    display: flex;
    padding-top: 24px;
    overflow: hidden;
}

.inputs-container {
    flex: 100%;
    overflow: scroll;
}

.data-container {
    flex: 70%;
    overflow: scroll;
}

.dialog-action-buttons {
    flex: 0.6;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 10%;
}

.dialog-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 24px;
}

.dialog-subtitle {
    font-size: 13px;
}

.MuiSelect-selectMenu {
    height: 3px !important;
    min-height: 3px !important;
}

.MuiInputLabel-formControl {
    top: -8px !important;
}

.input-element .MuiInputBase-input{
    height: 3px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 0px) scale(0.75) !important;
}

.input-element .MuiInputBase-root {
    line-height: 8px !important;
}

.MuiFormLabel-root {
    font-size: 13px !important;
    line-height: 16px !important;
}

.MuiInputLabel-outlined {
    transform: translate(14px, 22px) scale(1);
}

.MuiOutlinedInput-notchedOutline {
    border-color: #BAC4E3 !important;
}

.recents-dialog {
    margin-top: -16px;
    overflow: scroll;
}

.template-element-title {
    font-weight: bold;
}

.dialog-element-sub-title-switch {
    font-size: 11px;
    padding-top: 6px;
}

.all-elements-container {
    width: 100%;
}

.tree-view-progress {
    padding-left: 50%;
    padding-top: 25%;
}