body {
    height: 100%;
}

input.editor-template-name-input {
    width: 378px;
    height: 26px;
    border-width: 3px;
    border-style: solid;
}

.editor-content {
    padding-top: 50px;
}

#html {
    width: 96%;
    height: 100px;
    font-size: 15px;
    border-style: solid;
    border-width: 3px;
    margin-bottom: -84px;
    padding-left: 48px;
    padding-top: 11px;
}

div#type-menu.MuiPopover-root {
    position: fixed;
    left: 22%;
    top: 25%;
}

.type-menu {
    position: absolute;
    left: 22%;
}

.menu {
    position: relative;
    left: 92%;
    bottom: 48px;
}

div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    position: absolute;
    left: 113px;
}

.error-msg {
    font-weight: bold;
    font-size: 18px;
    color: #FF0000;
    text-align: center;
    background-color: #e9e9e9;
}

.options-hint {
    font-size: 12px;
}

.draggable-component {
    width: 50px;
    height: 50px;
    background-size: 23px 29px;
    background-repeat: no-repeat;
    background-image: url("../../images/drag_buttons.png");
    cursor: move;
}

.sticky {
    z-index: 4294967297;
    position: relative;
}

.collapse-btn {
    position: sticky;
    top: 175px;
}

#image-file-picker {
    position: relative;
    left: 76px;
    bottom: 10px;
}

#table-file-picker {
    position: relative;
    left: 76px;
    bottom: 10px;
}

.variable-reference-dropdown {
    width: 182px;
    position: relative;
    left: 72px;
    bottom: 10px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.download-template-btn,
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.save-template-btn {
    width: 100px;
    font-size: 13px;
}

.expand-button {
    background-color: #fff;
    border: 1px solid #5a5a5a;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 4px 0px #5a5a5a47;
    border-radius: 0px 4px 4px 0px;
    border-left: 0px;
    padding: 0.1em;
}

.add-element-container {
    width: 100%;
    border-color: #EAE6FF;
    border-style: dashed;
    border-width: 1px;
    height: 5em;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.add-element-btn {
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-color: #403294;
}

.template-flash-message {
    position: fixed;
    top: 0px;
    z-index: 1;
    text-align: center;
    width: 100%;
    color: red;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    padding-top: 1em;
    padding-bottom: 1em;
}
.buffer-save-text {
    font-size: 14px;
    color: #5a5a5a;
    text-align: center;
    margin-top: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.add-element-btn {
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-color: #403294;
}

.MuiTab-root {
    min-width: 90px !important;
    max-width: 100px !important;
}
.PrivateTabIndicator-root-15 {
    width: 90px !important;
}
.MuiTab-wrapper {
    font-size: 13px !important;
    text-transform: none !important;
}
.variables-list-container {
    padding: 0px 8px;
}
.MuiTabs-root {
    border-bottom: 1px solid #BAC4E3;
    margin-left: -10px;
    margin-right: -10px;
}
.PrivateTabIndicator-colorPrimary-16 {
    background-color: #426BBA;
    border-radius: 5px;
    border: 2px;
}
.ForwardRef-root-19 {
    box-shadow: none !important;
}
.ForwardRef-searchContainer-25 {
    margin: auto 16px !important;
    width: calc(100% - 50px) !important;
}
.variables-pane-header {
    display: flex;
}
.variable-pane-header-search {
    flex: 0.8;
}
.variable-pane-header-action-buttons {
    flex: 0.2;
    text-align: right;
}
.PrivateTabIndicator-colorSecondary-17 {
    background-color: #426BBA !important;
    border-radius: 5px;
    border: 2px;
}
.MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    color: #0052CC !important;
}
.MuiTab-textColorPrimary.Mui-selected {
    color: #0052CC !important;
}

.saving-text {
    margin-left: 4px;
}