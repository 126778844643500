.expressionBuilderField > div {
  padding: unset !important;
  border: none;
  width: unset;
  height: unset;
  outline: none;
}

.expressionBuilderField input{
  padding: unset;
  border: 1px solid;
  width: unset;
  height: unset;
  outline: none;
  background-color: white;
}

.MuiFormControl-root.MuiTextField-root.expressionBuilderField.MuiFormControl-fullWidth {
  width: 100%;
}

.MuiAutocomplete-root {
  width: 90% !important;
}