.metadata-container {
    width: 100%;
}
.meta-header {
    display: flex;
    justify-content: space-between;
    padding-top: 3em;
}

.meta-variables-hdr {
    font-size: 1.2em;
    padding-top: 1em;
}

.key-value-container {
    width: 100%;
    border-radius: 4%;
    background-color: #f4f4fc;
    position: relative;
}

.metadata-actions {
    position: absolute;
    right: 0em;
    top: .3em;
}

.metadata-key {
    padding-top: 1em;
    padding-left: 1em;
    margin-right: 16px;
    color: #333333;
    font-size: 13px;
    font-weight: 500;
}

.metadata-value {
    padding-left: 1em;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
    font-weight: 500;
}

.add-metadata-btn {
    margin-left: 10px;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 82, 204);
    min-width: 100px;
    font-size: 14px;
    text-transform: none;
    height: 40px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.add-metadata-btn {
    margin-left: 10px;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 82, 204);
    min-width: 80px;
    font-size: 14px;
    text-transform: none;
    height: 36px;
}

.search-metadata {
    margin-top: 7%;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.close-metadata-btn {
    margin-left: 10px;
    color: rgb(150, 151, 152);
    background-color: rgb(241, 243, 250);
    min-width: 80px;
    font-size: 14px;
    text-transform: none;
    height: 36px;
}
.meta-input-header {
    display: flex;
    flex-direction: column;
}
.meta-input-holder {
    display: flex;
}
.meta-action-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-bottom: 16px;
}

.search-bar-holder {
    margin-top: -8px;
}
.MuiOutlinedInput-root {
    position: relative;
    border-radius: 2px !important;
}
.MuiOutlinedInput-input {
    padding: 18.5px 18px !important;
}

.csv-metadata {
    font-size: 1em;
    padding-top: 2.5em;
}

.upload-metadata-hdr {
    padding-right: 4.5em;
}

.upload-metadata-input {
    padding-top: 0.5em;
}

.metadata-upload-options {
    padding-top: .5em;
    padding-left: .5em;
}

.MuiTypography-body1 {
    font-size: .9em !important;
}

.is-top-level {
    font-size: .9em;
    padding-top: 1.2em;
}