.variables-pane {
    width: 100%;
}

.search-variables {
    margin-top: 5%;
    box-shadow: none !important;
    height: 24px;
    background: none;
    border-bottom: 1px solid #EDF0F9;
    padding: 4px;
    border-radius: 0px;
}

.variable-container {
    position: relative;
    background-color: #f4f4fc;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 5%;
}

.variable-name {
    padding-top: 1em;
    padding-left: 1em;
    margin-right: 16px;
    color: #333333;
    font-size: 13px;
    font-weight: 500;
    overflow-wrap: break-word;
    max-width: 70%;
}

.variable-description {
    padding-left: 1em;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
    font-weight: 500;
}

.variable-type {
    position: absolute;
    top: 0em;
    right: 1em;
    color: #999999;
    font-size: 12px;
    font-weight: 500;
}

.variable-from-container {
    position: relative;
    background-color: #f4f4fc;
    border-radius: 4%;
    margin-top: -10px;
}

.variable-from-name {
    padding-left: 1em;
    font-size: 12px;
    font-weight: 500;
    color: #0052cc;
    padding-top: .5em;
    padding-bottom: .5em;

}
.MuiInputBase-input {
    font-size: 13px !important;
}
.ForwardRef-searchIconButton-22 {
    margin-right: -24px !important;
}
.ForwardRef-icon-23 {
    height: 18px !important;
    width: 18px !important;
}
.ForwardRef-iconButton-20 {
    height: 24px;
    width: 24px;
}
.ForwardRef-root-19 {
    height: 24px !important;
    background: none !important;
    border-bottom: 1px solid #EDF0F9 !important;
    padding: 4px !important;
    border-radius: 0px !important;
}
.variable-pane-header-action-buttons {
    font-size: 12px;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #0052CC;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.upload-input {
    display: none;
}
.MuiIconButton-root {
    padding: 0px !important;
}
.MuiIconButton-root:hover {
    background: none !important;
}