body {
  font-family: Helvetica;
  font-weight: lighter;
}

.content {
    background-color: #F1F3FA;
}

.editor-header {
    margin-top: 2em;
    font-size: 2em;
}

.document {
    margin-left: 15em;
    margin-right: 15em;
}

.template-name {
    display: inline;
}

.document-state {
    display: inline;
}

.document-details {
    margin-top: 2em;
    margin-bottom: 2em;
}

.document-preview {
    float: left;
    width: 98%;
}

.document-header {
  font-size: 16px;
  font-weight: bold;
  display: inline;
}

.document-name {
    width: 12%;
    height: 2em;
    border-style: solid;
    border-width: .25px;
}

.variable-input {
  width: 557px;
  height: 32px;
}

.variables {
  position: relative;
  top: 25px;
}

.variable-name {
  font-size: 15px;
  font-weight: bold;
}

.variable-dropdown {
  width: 257px;
  height: 27px;
}

.variable-desc {
  font-size: 13px;
}

#var-input-value {
  font-weight: bold;
}

.document-btns-container {
    margin-top: 3em;
    margin-bottom: 3em;
}

.document-btns {
    display: flex;
    justify-content: space-between;
}

button#preview-doc-btn.MuiButtonBase-root.MuiButton-root.MuiButton-text,
button#download-doc-btn.MuiButtonBase-root.MuiButton-root.MuiButton-text,
button#save-doc-btn.MuiButtonBase-root.MuiButton-root.MuiButton-text,
button#update-template-btn.MuiButtonBase-root.MuiButton-root.MuiButton-text,
button#acl-editor-doc-btn.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    width: 100%;
    background-color: #ececec;
    font-family: Helvetica;
    font-size: 15px;
    border: none;
}

.metadata {
    width: 100%;
    border: 1px solid #dadada;
    border-radius: 8px;
    background-color: #f0f0f0;
    margin-top: 3em;
}

.key-hdr {
  position: relative;
  left: 13%;
  font-weight: bold;
}

.value-hdr {
  margin-left: 61%;
  position: relative;
  bottom: 33px;
  font-weight: bold;
}

.key-txt {
  position: relative;
  left: 6%;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  text-align: center;
  font-size: 13px;
  width: 18%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
}

.value-txt {
  position: relative;
  border-style: solid;
  border-width: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 32%;
  bottom: 49px;
  width: 61%;
  border-radius: 8px;
  text-align: center;
  font-size: 13px;
}

#title-body {
  border: 1px solid #d3d3d3;
  width: 77%;
  border-radius: 19px;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  background-color: #f0f0f0;
  margin-bottom: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
}

#section-body,
#tbl-of-contents-body {
  border: 1px solid #d3d3d3;
  width: 53%;
  border-radius: 19px;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  background-color: #f0f0f0;
  margin-left: 13%;
  margin-bottom: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
}

#html-body {
  border: 2px dotted #d3d3d3;
  width: 77%;
  border-radius: 2px;
  font-size: 13px;
  padding: 20px 20px;
  background-color: #f0f0f0;
  margin-bottom: 30px;
}

#sub-html-body {
  border: 2px dotted #d3d3d3;
  width: 35%;
  border-radius: 2px;
  font-size: 13px;
  padding: 20px 20px;
  background-color: #f0f0f0;
  margin-bottom: 30px;
  margin-left: 21%;
}

#subtemplate-body {
  display: inline;
  padding-bottom: 30px;
  border: 1px solid #d3d3d3;
  width: 53%;
  border-radius: 19px;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  background-color: #f0f0f0;
  margin-left: 13%;
  margin-bottom: 30px;
  padding-top: 13px;
}

.variable-input-field {
  width: 16%;
  height: 30px;
}

#header-body,
#footer-body {
  border: 1px solid #d3d3d3;
  width: 80%;
  border-radius: 8px;
  background-color: #f0f0f0;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.doc-nested-variable {
  margin-left: 48%;
  position: relative;
  right: 253px;
}

.error-msg {
    font-weight: bold;
    font-size: 18px;
    color: #FF0000;
    text-align: center;
    background-color: #e9e9e9;
}

.document-flash-message {
    position: fixed;
    top: 0px;
    z-index: 1;
    text-align: center;
    width: 100%;
    color: red;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    padding-top: 1em;
    padding-bottom: 1em;
}

.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.1) !important;
    background-color: rgba(0, 0, 0, 0.03) !important;
}

.document-buffer-save-text {
  font-size: 14px;
  color: #5a5a5a;
  text-align: center;
  margin-top: 12px;
  font-weight: 600;
  display: flex;
}

.saving-text {
  margin-left: 4px;
}

.MuiTab-root {
  min-width: 90px !important;
  max-width: 100px !important;
}

.PrivateTabIndicator-root-15 {
  width: 90px !important;
}

.MuiTab-wrapper {
  font-size: 13px !important;
  text-transform: none !important;
}

.variables-list-container {
  padding: 0px 8px;
}

.MuiTabs-root {
  border-bottom: 1px solid #BAC4E3;
  margin-left: -10px;
  margin-right: -10px;
}

.PrivateTabIndicator-colorPrimary-16 {
  background-color: #426BBA;
  border-radius: 5px;
  border: 2px;
}

.PrivateTabIndicator-colorSecondary-17 {
  background-color: #426BBA !important;
  border-radius: 5px;
  border: 2px;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  color: #0052CC !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #0052CC !important;
}
