.table-cell-align {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.table-icon {
    visibility: hidden;
    margin-top: 7px;
    cursor: pointer;
}