.homepage-hdr {
    color: #282c34;
    font-family: Helvetica;
    font-size: 50px;
    margin-left: 50%;
    position: relative;
    right: 200px;
    margin-top: 15%;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-text.homepage-btn {
    color: #282c34;
    font-family: Helvetica;
    font-size: 20px;
    padding-right: 50px;
    width: 100%;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-text.home-logout-btn {
    color: #282c34;
    font-family: Helvetica;
    font-size: 20px;
    position: absolute; 
    right: 30px;
    top: 30px;
}
.heading {
    font-weight: 500;
}
.ml-32 {
    margin-left: 16px;
}
.pointer {
    cursor: pointer;
}

.homepage {
    padding-top: 6em;
}

.dashboard-hdr, .recents-hdr {
    font-weight: bold;
    font-size: 1em;
    padding-left: 2em;
}

.recents {
    padding-top: 2em;
}

.recents-card-views {
    padding-left: 2em;
}

.recents-buffers {
    margin-left: 8px;
    margin-top: 40px;
}
.template-buffer {
    margin-left: 16px;
}
