button.MuiButtonBase-root.MuiButton-root.MuiButton-text.template-name-fixed {
    background-color: #f0f0f0;
    height: auto;
    border: 1px solid #d3d3d3;
}

.table_of_contents-fixed {
    border: 1px solid #d3d3d3;
    width: 77%;
    border-radius: 2px;
    text-align: center;
    background-color: #f0f0f0;
    margin-bottom: 30px;
    padding: 13px 20px;
}

.table_of_contents-header-fixed {
    font-size: 17px;
}

.variable-textarea-fixed {
    position: relative;
    width: 99.5%;
    height: 13em;
}

.variable-value-label-fixed .tox.tox-tinymce {
    width: 100%;
}

.variable-dropdown {
    width: 70%;
    height: 27px;
    position: relative;
    left: 13px;
}

.table-fixed {
    width: 81%;
}

.reference-fixed {
    border: 1px solid #e9e9e9;
    width: 78%;
    font-size: 19px;
    background-color: #e9e9e9;
    margin-bottom: 15px;
    padding: 9px 20px;
    border-radius: 2px;
}

.variable-header-fixed {
    font-weight: bold;
}

.reference-fixed-header {
    font-size: 15px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.reference-link {
    position: relative;
    left: 10px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.get-var-data-btn {
    position: relative;
    top: 18px;
    left: 3%;
}

.style-fixed {
    background-color: lightgray;
    width: 81%;
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    margin-bottom: 30px;
}

.style-fixed-header {
    padding-top: 1em;
    padding-left: 1em;
    font-size: 16px;
    font-weight: bold;
}

.style-fixed-text {
    width: 50%;
    position: relative;
    left: 1em;
}

.style-styling-info {
    position: relative;
    left: 17px;
}