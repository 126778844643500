.main-hdr {
    font-size: 33px;
    margin-left: 50%;
    position: relative;
    right: 125px;
    top: 70px;
}

.content-type-hdr {
    color: #282c34;
    padding-top: 19px;
    padding-left: 15px;
    font-weight: bold;
    color: #282c34;
    font-size: 20px;
}

.content-name {
    background-color: #eaeaea;
    height: 500px;
    width: 75%;
    margin-left: 13%;
    margin-top: 8%;
    overflow: scroll;
}

.filters {
    background-color: #eaeaea;
    height: 200px;
    width: 75%;
    margin-left: 13%;
    margin-top: 3%;
    height: 200px;
    padding-bottom: 40px;
    /* border-style: solid;
    padding-bottom: 58px;
    border-width: 2px;
    border-color: lightgray; */
}

.filters-hdr {
    font-size: 20px;
    font-weight: bold;
    color: #282c34;
    padding-top: 19px;
    padding-left: 15px;
}

.name {
    position: relative;
    left: 91px;
}

.owner {
    position: relative;
    left: 163px;
    top: 25px;
}

#name-input-value {
    width: 78%;
    height: 40px;
}

#owner-input-value {
    width: 78%;
    height: 40px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.content-btn {
    width: 100%;
    background-color: lightgray;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.content-btn-selected {
    width: 100%;
    background-color: blue;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-text.doc-action-btn {
    background-color: #ececec;
    margin-left: 13%;
    margin-top: 2%;
}