body {
    font-family: Helvetica; 
    font-weight: lighter;
}

/* --- */
.editor-item-button-remove {
	text-align: center;
	margin: auto;
}

.list-inputs {
    position: relative;
}

button#multi-list.MuiButtonBase-root.MuiIconButton-root.editor-item-button-remove,
button#single-list.MuiButtonBase-root.MuiIconButton-root.editor-item-button-remove {
    position: absolute;
    right: 0px;
    bottom: -12.5px;
}

.editor-item-title-label {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
}

.editor-item-subtitle-label {
    text-align: right;
    font-size: 0.9em;
    font-weight: bold;
}

.list-item-subtitle-label {
    font-size: 0.9em;
    font-weight: bold;
}

.editor-item-text-input {
    width: 100%;
    border: 0;
    outline: none;
    font-size: 1em;
}

.editor-item-list-input {
	width: 90%;
}

.editor-item-textarea-input {
	width: 100%;
	height: 11em;
}

.editor-item-richtextarea-input {
	width: 100%;
	height: 7em;
}

.editor-item-dropdown-title {
    font-size: .9em;
    font-weight: bold;
}

.editor-item-label-text-name, .editor-item-label-dropdown-name, .editor-item-label-checkbox-name,
.editor-item-label-list-name, .editor-item-label-datasource-name, .editor-item-label-image-name,
.editor-item-label-textarea-name, .editor-item-label-richtextarea-name, .editor-item-label-reference-name {
    font-size: .9em;
    font-weight: 500;
}

.editor-item-label-text-type, .editor-item-label-dropdown-type, .editor-item-label-checkbox-type,
.editor-item-label-list-type, .editor-item-label-datasource-type, .editor-item-label-image-type,
.editor-item-label-textarea-type, .editor-item-label-richtextarea-type, .editor-item-label-reference-type {
    font-size: 11px;
    color: #595959;
}

.editor-item-label-text-description, .editor-item-label-dropdown-description, .editor-item-label-checkbox-type,
.editor-item-label-list-description, .editor-item-label-datasource-description, .editor-item-label-image-description,
.editor-item-label-textarea-description, .editor-item-label-richtextarea-description, .editor-item-label-reference-description {
    font-size: .8em;
}

.editor-item-label-dropdown-dropdown, .editor-item-label-checkbox-dropdown,
.editor-item-label-list-dropdown {
    font-size: .9em;
}

label[class*='editor-item-label-style'], label[class*='editor-item-label-datasource']  {
    font-size: .9em;
}

.editor-item-label-reference-selected_value {
    font-size: .9em;
}

.editor-item-label-table-title {
    font-size: .9em;
    font-weight: 500;
}

.editor-item-label-template-name {
    font-size: .9em;
    font-weight: 500;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    font-size: 13px;
}

.editor-item-label-table-datasource, .editor-item-label-table-columns, .editor-item-label-table-rows  {
    font-size: .9em;
}

.editor-item-label-switch-name, .editor-item-label-_meta_value-name, .editor-item-label-_meta_default-name,
.editor-item-label-loop-name, .editor-item-label-_meta_else-name, .editor-item-label-_meta_value-name,
.editor-item-label-_meta_default-name {
    background-image: url("../../images/decision.svg");
    height: 20px;
    width: 20px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    font-size: 1em;
    color: #006644;
    font-weight: 400;
    background-color: #E3FCEF;
    margin-left: 6px;
    margin-right: 4px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 5px;
    padding-left: 24px;
}

.editorarea-item-container {
	margin-bottom: 5px;
	border-radius: 3px;
}
/* --- */

.document-header {
    font-size: 16px;
    font-weight: bold;
    display: inline;
}

.variable-input {
    width: 557px;
    height: 32px;
}

.variables {
    position: relative;
    top: 25px;
}

/* #var-input-value {
    font-weight: bold;
} */

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.action-btns {
    margin-left: 30px;
}

#title-body {
    border: 1px solid #d3d3d3;
    width: 77%;
    border-radius: 19px;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    background-color: #f0f0f0;
    margin-bottom: 30px;
    padding-top: 13px;
    padding-bottom: 13px;
}

#sub-html-body {
    border: 2px dotted #d3d3d3;
    width: 35%;
    border-radius: 2px;
    font-size: 13px;
    padding: 20px 20px;
    background-color: #f0f0f0;
    margin-bottom: 30px;
    margin-left: 21%;
}

#subtemplate-body {
    display: inline;
    padding-bottom: 30px;
    border: 1px solid #d3d3d3;
    width: 53%;
    border-radius: 19px;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    background-color: #f0f0f0;
    margin-left: 13%;
    margin-bottom: 30px;
    padding-top: 13px;
}

.variable-input-field {
    width: 16%;
    height: 30px;
}

#header-body, #footer-body {
    border: 1px solid #d3d3d3;
    width: 80%;
    border-radius: 8px;
    background-color: #f0f0f0;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* .doc-nested-variable {
    margin-left: 48%;
    position: relative;
    right: 253px;
} */

.error-msg {
    font-weight: bold;
    font-size: 18px;
    color: #FF0000;
    text-align: center;
    background-color: #e9e9e9;
}

body {
    height: 100%;
}

.user-elements {
    display: flex;
    justify-content: space-between;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
    background-color: #5a5a5a;
    overflow: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

.editor-content {
    padding-top: 50px;
}

.subtemplate-label {
    display: inherit;
}

.subtemplate-section-input {
    width: 86%;
    height: 39px;
    font-size: 14px;
}

.subtemplate-name-input {
    width: 89%;
    height: 39px;
    font-size: 14px;
    margin-bottom: 22px;
    display: inherit;
}

.subtemplate-name {
    height: 40px;
    width: 46%;
}

.variable-hdr {
    text-align: center;
}

#text, #dropdown, #checkbox, #list, #variable {
    border-radius: 4px;
    padding-left: 43px;
    width: 220px;
    margin-bottom: 10px;
    background-color: #acbbbe;
    padding-left: 46px;
    padding-right: 52px;
}

.editor-var-type, .editor-var-name, .editor-var-options {
    display: inherit;
    width: 100%;
    margin-bottom: 14px;
    height: 25px;
    font-size: 13px;
}

.editor-var-description {
    height: 50px;
    width: 100%;
    font-size: 13px;
    margin-bottom: 14px;
}

button.MuiButtonBase-root.MuiIconButton-root.add-element-btn {
    position: relative;
}

button#element.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    border-radius: 50px;
    height: 4.5em;
    width: 100%;
    font-size: .8em;
}

div#type-menu.MuiPopover-root {
    position: fixed;
    left: 22%;
    top: 25%;
}

.type-menu {
    position: absolute;
    left: 22%;
}

.menu {
    position: relative;
    left: 92%;
    bottom: 48px;
}

div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    position: absolute;
    left: 113px;
}

.editor-content-subelements {
    position: relative;
    left: 11%;
}

.template-info-container {
    background-color: #e9e9e9;
    width: 100%;
    height: 9em;
}

.template-info {
    margin-top: 30px;
    text-align: center;
}

label.editor-template-name {
    font-weight: bold;
    font-size: 1em;
    color: #282c34;
}

input.editor-template-name {
    width: 70%;
    height: 2em;
    border-width: 3px;
    border-style: solid;
}

.editor-template-includes {
    font-weight: bold;
    font-size: 1em;
    color: #282c34;
}

.error-msg {
    font-weight: bold;
    font-size: 18px;
    color: #FF0000;
    text-align: center;
    background-color: #e9e9e9;
}

.template-content {
    /* border-style: solid;
    border-width: .5px; */
    min-height: 100vh;
    /* border-color: lightgray; */
    width: 100%;
    margin: auto;
}

.var-name-label {
    display: inherit;
}

.subtemplate-hdr {
    display: inline;
    font-size: 18px;
    position: relative;
    bottom: 18px;
}

.sticky {
    z-index: 1;
    position: relative;
}

#image-file-picker {
    position: relative;
    left: 76px;
    bottom: 10px;
}

#table-file-picker {
    position: relative;
    left: 76px;
    bottom: 10px;
}

.selected-file-text {
    font-size: 13px;
    position: relative;
    left: 13.5%;
    font-weight: bold;
}

.rdw-editor-wrapper {
    box-sizing: content-box;
    border-color: #c1c1c1;
    border-width: 1px;
    border-style: solid;
}

.template-editor-variables {
    position: fixed;
    width: 25%;
}

.variable-file-upload {
    padding-left: 15px;
}

.bulk-create-variables {
    position: relative;
    top: 140px;
}

.upload-variables-from-csv {
    font-size: 1em;
    font-weight: bold;
    padding-bottom: 9px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.upload-variables-csv {
    padding-top: 10px;
}

.template-editor {
    width: 100%;
}

.MuiFormControl-root.MuiTextField-root.MTableToolbar-searchField-10 {
    width: 50% !important;
}

.MuiFormControl-root.MuiTextField-root.jss10 {
    width: 50% !important;
}

.template-editor-variables .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
    height: 27em;
}

.template-editor-variables .Component-horizontalScrollContainer-12 {
    height: 27em;
}

.template-editor-variables .jss12 {
    height: 19em;
}

.filter-documents {
    position: relative;
    left: 3%;
    font-size: 15px;
    margin-top: 13px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.get-document-elements-btn {
    position: relative;
    left: 2%;
    margin-top: 12px;
}

.reference-formatting {
    position: relative;
    left: 3%;
    margin-top: 29px;
}

.selected-ref-fixed {
    position: relative;
    left: 3%;
    font-size: 15px;
}

.source-url-input {
    margin-right: 85px;
    width: 30%;
    position: relative;
    top: 11px;
    left: 109px;
}

.flash-message {
    position: fixed;
    top: 0px;
    z-index: 1;
    text-align: center;
    width: 100%;
    color: red;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    padding-top: 1em;
    padding-bottom: 1em;
}

.element-container {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border: 1px dashed #EAE6FF;
    border-radius: 4px;
}

.element-detail-container {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
    height: 30px;
}

.element {
    margin-left: 12px;
    margin-right: 12px;
}
.element-title-container {
    background-color: #EAE6FF;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    margin-top: -2px;
    margin-left: -1px;
    max-height: 14px;
}
.element-title {
    color:#403294;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
}
.element-container #action-button-container {
    display: none;
}
.element-container:hover #action-button-container {
    display: block !important;
}

#action-button-container {
    width: 100%;
    background-color: #EAE6FF;
    border-radius: 2px;
    margin-top: -2px;
    margin-right: -1px;
}
#action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}
.action-button {
    height: 18px;
    width: 33px;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: center;
}


.template-action-button-1 {
    background-image: url("../../images/move-filled.svg");
}

.template-action-button-2 {
    background-image: url("../../images/add-outline.svg");
}

.template-action-button-3 {
    background-image: url("../../images/edit-outline.svg");
}

.template-action-button-4 {
    background-image: url("../../images/duplicate-outline.svg");
}

.template-action-button-5 {
    background-image: url("../../images/comment-outline.svg");
}

.template-action-button-6 {
    background-image: url("../../images/close-outline.svg");
}
.toolbar-button {
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
}

.toolbar-button:hover {
    background-color: rgba(61, 54, 143, 0.1);
}

.multi-list-container, .single-list-container {
    position: relative;
}

.MTableToolbar-title-45, .MTableToolbar-title-34{
    overflow: inherit !important;
}

.MuiTypography-root .MuiTypography-h6 {
    overflow: inherit !important;
}