.confirmation-message {
    position: fixed;
    top: 0px;
    z-index: 1;
    text-align: center;
    width: 100%;
    font-size: 17px;
    background-color: white;
    padding-top: 1em;
    padding-bottom: 1em;
    white-space: pre-line;
    color: red;
}