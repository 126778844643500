.html-text {
    line-height: 2em;
    position: relative;
    padding: 2px;
    border: 1px solid transparent;
    color: #2b2b2c;
    font-size: 14px;
}

.html-text #action-button-container {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    background-color: #EAE6FF;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0px #0000000d;
}

#action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}


.html-text:hover {
    border: 1px solid #EAE6FF;
    border-style: dashed;
    border-radius: 2px;
}

.action-button {
    height: 18px;
    width: 33px;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: center;
}


.document-action-button-1 {
    background-image: url("../../images/history-icon-outline.svg");
    height: 18px;
    width: 33px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center;
}

.document-action-button-2 {
    background-image: url("../../images/search-icon-outline.svg");
}

.document-action-button-3 {
    background-image: url("../../images/comment-icon-outline.svg");
}

.document-action-button-4 {
    background-image: url("../../images/refresh.svg");
}

.document-action-button-5 {
    background-image: url("../../images/down-arrow-filled.svg");
}

.document-action-button-6 {
    background-image: url("../../images/up-arrow-filled.svg");
}

.html-text:hover #action-button-container {
    display: block !important;
}

.toolbar-button {
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
}

.toolbar-button:hover {
    background-color: rgba(61, 54, 143, 0.1);
}

.title-component {
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    padding: 2px;
    border: 1px solid transparent;
    min-height: 24px;
    padding-top: 10px;
    margin-top: 16px;
}

.title-component #action-button-container {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    background-color: #EAE6FF;
    border-radius: 2px;
    /* padding-top: 6px;
    padding-bottom: 6px; */
    box-shadow: 1px 1px 4px 0px #0000000d;
}

.title-component:hover {
    border: 1px solid #EAE6FF;
    border-style: dashed;
    border-radius: 2px;
}

.title-component:hover #action-button-container {
    display: block !important;
}

.switch-expression-container {
    min-width: 140px;
    display: inline-block;
    flex-direction: row;
    background-color: #E3FCEF;
    border-radius: 2px;
}

.switch-image-not-selected-container {
    background-color: #E3FCEF;
}

.switch-image-container {
    flex: 1;
    background-color: #006644;
}

.switch-expression-detail {
    display: flex;
    flex-direction: row;
    flex: 2;
    background-color: #E3FCEF;
    margin-left: 6px;
    margin-right: 4px;
}

.switch-image-not-selected {
    background-image: url("../../images/decision-outline.svg");
    height: 20px;
    width: 20px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center;
}

.switch-inner-container {
    display: flex;
    align-items: center;
    padding: 6px;
}

.switch-name {
    font-size: 14px;
    color: #006644;
    font-weight: 400;
}

.switch-expression {
    font-size: 14px;
    color: #006644;
    font-weight: 600;
    margin-left: 4px;
}

.switch-container {
    position: relative;
    border: 1px solid transparent;
    margin-top: 8px;
    margin-bottom: 8px;
}

.switch-container #action-button-container {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    background-color: #EAE6FF;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0px #0000000d;
}

.switch-container .width-4 {
    width: 120px !important;
}

.switch-container:hover {
    border: 1px solid #EAE6FF;
    border-style: dashed;
    border-radius: 2px;
}

.switch-container:hover #action-button-container {
    display: block !important;
}

.comment-text {
    line-height: 2em;
    position: relative;
    padding: 2px;
    border: 1px solid transparent;
    color: #C53137;
    font-size: 12px;
}

.comment-text #action-button-container {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    background-color: #EAE6FF;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0px #0000000d;
}

.comment-text:hover {
    border: 1px solid #EAE6FF;
    border-style: dashed;
    border-radius: 2px;
}

.comment-text:hover #action-button-container {
    display: block !important;
}

.reference-text {
    font-size: 14px;
    font-weight: 400;
    color: #0052CC;
}

.list-element {
    list-style-type: none;
    font-size: 14px;
    font-weight: 400;
    color: #0052CC;
    line-height: 2em;
    text-decoration: none;
}

.table-of-contents #action-button-container {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    background-color: #EAE6FF;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0px #0000000d;
}

.table-of-contents:hover {
    border: 1px solid #EAE6FF;
    border-style: dashed;
    border-radius: 2px;
}

.table-of-contents:hover #action-button-container {
    display: block !important;
}

.table-of-contents {
    position: relative;
    border: 1px solid transparent;
}

.toc-title {
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    padding: 2px;
    border: 1px solid transparent;
    min-height: 24px;
    padding-top: 10px;
}

.reference-container #action-button-container {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    background-color: #EAE6FF;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0px #0000000d;
}

.reference-container:hover {
    border: 1px solid #EAE6FF;
    border-style: dashed;
    border-radius: 2px;
}

.reference-container:hover #action-button-container {
    display: block !important;
}

.reference-container {
    position: relative;
    border: 1px solid transparent;
}

.indent-accordian{
    display: none;
    margin-left: 16px;
}
.indent {
    margin-left: 16px;
}

.table-cell {
    color: #2b2b2c;
    font-size: 14px;
    min-width: 50px;
    max-width: 250px;
    padding: 0.5em 1em;
    border: 1px solid #dddddd;
    line-height: 1.4;
    font-weight: 500;
}

.table-cell-shaded {
    color: #000000;
    background: #F8F9FC;
    text-align: left;
}

.table-border {
    border-collapse: collapse;
}

.table-cell-heading {
    font-weight: 600;
}

.table-container {
    position: relative;
    border: 1px solid transparent;
}

.table-container #action-button-container {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    background-color: #EAE6FF;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0px #0000000d;
}

.table-container:hover {
    border: 1px solid #EAE6FF;
    border-style: dashed;
    border-radius: 2px;
}

.table-container:hover #action-button-container {
    display: block !important;
}

table {
    border: none;
}

.style-container {
    position: relative;
    border: 1px solid transparent;
    color: #2b2b2c;
    font-size: 14px;
}

.style-container:hover {
    border: 1px solid #EAE6FF;
    border-style: dashed;
    border-radius: 2px;
}

.style-container:hover #action-button-container {
    display: block !important;
}

.style-container #action-button-container {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    background-color: #EAE6FF;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0px #0000000d;
}

.style-element-action {
    font-weight: bold;
}

.citations-container, .abbreviations-container {
    position: relative;
    border: 1px solid transparent;
    color: #2b2b2c;
    font-size: 14px;
}

.citations-title, .abbreviations-title {
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    padding: 2px;
    border: 1px solid transparent;
    min-height: 24px;
    padding-top: 10px;
}

.citations-container:hover, .abbreviations-container:hover {
    border: 1px solid #EAE6FF;
    border-style: dashed;
    border-radius: 2px;
}

.citations-container:hover #action-button-container, .abbreviations-container:hover #action-button-container {
    display: block !important;
}

.citations-container #action-button-container, .abbreviations-container #action-button-container {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    background-color: #EAE6FF;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0px #0000000d;
}