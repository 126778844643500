.datasource-table-preview {
    position: relative;
    top: .5em;
    left: 9px;
    width: 96%;
}

.download-datasource-data {
    position: relative;
    top: 10px;
    font-size: 13px;
}

.preview-variables-prompt {
    position: relative;
    left: 6px;
    font-size: 14px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.submit-variables-btn {
    position: relative;
    right: 5px;
    top: 5px;
}

.fetch-datsource-preview-err {
    position: relative;
    left: 11px;
    font-size: .75em;
    color: red;
}
