.recent-templates-view-container{
    height: 300px;
    width: 420px;
    padding: 10px;
    text-align: left;
    overflow-y: scroll;
    overflow-x: hidden;
}

.completed-icon-view{
    height: 50px;
    width: 50px;
    border-radius: 5px;
    align-items: center;
    margin-top: 10%;
}

.template-completion-icon{
    padding: 5px;
    font-size: 25px;
}

.document-status-icon-view{
   margin-top: 10px;
}

.templates-reviews-container{
    display: flex;
    flex-flow: row;
    margin-left: 10px;
    margin-top: 40px;
}

.templates-reviews-count-view{
    margin: auto 2px;
}

.status-text{
    padding: 5px;
    border-radius: 5px;
    background-color:  rgb(212, 212, 212);
}

.templated-highlighted{
    cursor: pointer;
    margin-top: 20px;
    margin-left: 50%;
    width: 40px;
    font-size: 25px;    
}

.template-more-options{
    margin-top: 10px;
    cursor: pointer;
}

.list-empty-view{
    opacity: 0.5;
    display: flex;
    width: 400px;
}

.list-empty-text{
    padding: 5px;
}
