
.profile {
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    display: flex;
}

.user-info {
    width: 20%;
    position: relative;
    left: 110px;
    top: 75px;
}

.user-name {
    font-size: 25px;
}

.user-img {
    height: 250px;
    width: 250px;
    background-color: #cac9c9;
    /* background-image: url("../../images/profile-img.png"); */
    border-radius: 150px;
    position: relative;
    right: 39px;
    top: 14px;
}

.user-email {
    position: relative;
    top: 35px;
}

.user-system-info {
    width: 80%;
    position: relative;
    top: 91px;
    left: 115px;
}

#group-info {
    display: none;
}

#role-info {
    display: none;
} 